html {
  height: 100%;
}

body {
  height: 100%;
  width: 100%;
  margin: 0;
  overflow: hidden;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.react-calendar-heatmap .color-empty { fill: #8cc665; }
.react-calendar-heatmap .color-scale-1 { fill: #d6e685; }
.react-calendar-heatmap .color-scale-2 { fill: #dab40f; }
.react-calendar-heatmap .color-scale-3 { fill: #fd0f23; }
.react-calendar-heatmap-weekday-label, .react-calendar-heatmap-month-label {
  font-size: 0.5em
}

text {
  fill: #fff;
}